import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../axiosInstance';

const endpointUrl = 'https://us-central1-kosha-moves-dev.cloudfunctions.net/sendEmail';

function Contact(props) {
  const [inputs, setInputs] = useState({});
  const [showAlert, setAlert] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    setInputs(values => ({...values, [id]: value}))
  }

  const handleSubmit = (event) => {
      const message = `Name: ${inputs.name} * Email: ${inputs.email} * Message: ${inputs.message}`
      const sendEmailBody = {
          data: {
              toEmail: 'info@koshamoves.ca',
              subject: 'Email from Kosha Website',
              message: message
          }
      };
    event.preventDefault();
    axiosInstance.post(`${endpointUrl}`, sendEmailBody)
        .then(response => {
            console.log(sendEmailBody.toString());
            setAlertMessage('Your message was sent successfully!');
            setSeverity('success');
            setAlert(true);
            setInputs(() => {});
        })
        .catch(error => {
            setAlertMessage('Oops! There was an error sending your message. Please try again.')
            setSeverity('error');
            setAlert(true);
        });    
  }

  return (
    <div>
    <div id="contact">
      <div className="container">
        <div className="col-md-8">
          <div className="row">
            <div className="section-title">
              <h2>Get In Touch</h2>
              <p>
                Please fill out the form below to send us an email and we
                will get back to you as soon as possible.
              </p>
            </div>
            <form name="sentMessage" id="contactForm" onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      required="required"
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      required="required"
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  required
                  onChange={handleChange}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div id="success"></div>
              <button type="submit" className="btn btn-custom btn-lg">
                Send Message
              </button>
            </form>
            <Box sx={{ width: '100%' }}>
      <Collapse in={showAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      </Collapse>
      </Box>
          </div>
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="contact-item">
            <h3>Contact Info</h3>
            <p>
              <span>
                <i className="fa fa-map-marker"></i> Address
              </span>
              {props.data ? props.data.address : "loading"}
            </p>
          </div>
         {/*  <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> Phone
              </span>{" "}
              {props.data ? props.data.phone : "loading"}
            </p>
          </div> */}
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope"></i> Email
              </span>{" "}
              {props.data ? props.data.email : "loading"}
            </p>
          </div>
        </div>            
      </div>
    </div>
    <div id="footer">
      <div className="container text-center">
        <p>
          &copy; 2022 Kosha Technologies Ltd.
        </p>
      </div>
    </div>    
  </div>    
  )
}

export default Contact;